<template>
    <div>
        <el-table :data="goodsList" border style="width: 100%; margin-top: 20px" size="medium"
                  :header-cell-style="{fontWeight: 'normal', height: '46px', color: '#666', background: '#F5F5F5'}"
                  :cell-style="{fontSize: '14px',color: '#333'}">
            <el-table-column label="排名" align="center" width="60">
                <template slot-scope="scope">
                    <div class="ranking" v-if="scope.row.sort === 1 || scope.row.sort === 2 || scope.row.sort === 3">{{scope.row.sort}}</div>
                    <div v-else>{{scope.row.sort}}</div>
                </template>
            </el-table-column>
            <el-table-column label="商品信息" min-width="300">
                <template slot-scope="scope">
                    <div  class="goods-wrapper">
                        <!--                        <p class="category">所属类目：{{ scope.row.category }}</p>-->
                        <div class="goods-box">
                            <div class="goods-cover">
                                <img :src="scope.row.image ? scope.row.image : require('../../../assets/images/user_img.png')" alt="">
                            </div>
                            <div class="goods-right">
                                <div class="text-overflow-2 goods-title" :title="scope.row.name">
                                    {{ scope.row.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="all_amount" align="center" label="金额指数"></el-table-column>
            <el-table-column prop="all_conversion_num" align="center" label="单量指数"></el-table-column>
            <el-table-column prop="all_click_num" align="center" label="点击指数"></el-table-column>
            <el-table-column prop="all_conversion_num" align="center" label="转化指数"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-link :underline="false" class="link-blue-light" @click="toDetail(scope.row)">详情</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="operation-pagination" style="margin: 20px 0 0;"
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       :total="listPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="pagesCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                goodsList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
            }
        },
        mounted() {
            this.getGoodsList()
        },
        methods: {
            // 获取人气列表
            getGoodsList() {
                let params = {
                    type: 2,
                    page: this.listPages.currentPageNum,
                    limit: this.listPages.eachPageNum,
                }
                this.$http.axiosGetBy(this.$api.storeSelectionDataAnalysisGoods, params, (res) => {
                    if (res.code === 200) {
                        this.goodsList = res.data.list
                        this.listPages.total = res.data.total
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 切换分页
            pagesCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getGoodsList()
            },
            // 详情
            toDetail(row) {
                this.$router.push({
                    path: '/student/operationpromotion/commoditylist/treaddetail',
                    query: {
                        id: row.id,
                        tabName: '人气榜单'
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .goods-wrapper {
        .goods-box {
            display: flex;
            align-items: center;
            .goods-cover {
                width: 48px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                border: 1px solid #eee;
                box-sizing: border-box;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .goods-right {
                width: 1%;
                flex: 1;
                .goods-title {
                    color: #333;
                    line-height: 18px;
                    transition: all .3s;
                    &:hover {
                        /*color: #FD4446;*/
                    }
                }

            }
        }
    }
    ::v-deep .el-table {
        min-height: calc(100vh - 328px);
        .el-table__body-wrapper {
            .el-table__row {
                .ranking {
                    width: 24px;
                    height: 24px;
                    line-height: 24px;
                    text-align: center;
                    font-weight: 500;
                    background-size: cover;
                    display: inline-block;
                }
                &:first-child {
                    .ranking {
                        background: url("../../../assets/images/dataCenter/one.png") no-repeat center;
                    }
                }
                &:nth-of-type(2) {
                    .ranking {
                        background: url("../../../assets/images/dataCenter/two.png") no-repeat center;
                    }
                }
                &:nth-of-type(3) {
                    .ranking {
                        background: url("../../../assets/images/dataCenter/three.png") no-repeat center;
                    }
                }
            }
        }
    }
</style>