<template>
    <div>
        <el-table :data="brandList" border style="width: 100%; margin-top: 20px" size="medium"
                  :header-cell-style="{fontWeight: 'normal', height: '46px', color: '#666', background: '#F5F5F5'}"
                  @sort-change="sortByEvent"
                  :cell-style="{fontSize: '14px',color: '#333'}">
            <el-table-column label="排名" align="center" width="60">
                <template slot-scope="scope">
                    <div class="ranking" v-if="scope.row.sort === 1 || scope.row.sort === 2 || scope.row.sort === 3">{{scope.row.sort}}</div>
                    <div v-else>{{scope.row.sort}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="name" label="品牌信息" min-width="200"></el-table-column>
            <el-table-column prop="all_amount" align="center" label="金额指数" sortable="custom"></el-table-column>
            <el-table-column prop="all_conversion_num" align="center" label="单量指数" sortable="custom"></el-table-column>
            <el-table-column prop="all_click_num" align="center" label="点击指数" sortable="custom"></el-table-column>
            <el-table-column prop="all_conversion_num" align="center" label="转化指数" sortable="custom"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-link :underline="false" class="link-blue-light" @click="toDetail(scope.row)">详情</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="operation-pagination" style="margin: 20px 0 0;"
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       :total="listPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="pagesCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import _ from "underscore";
    export default {
        data() {
            return {
                brandList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                brandListTmp: [],
                brandListData: []
            }
        },
        props: {
            period: {
                type: Number,
                default: 1
            }
        },
        mounted() {
            this.getBrandList()
        },
        watch: {
            period() {
                this.getBrandList();
            }
        },
        methods: {
            sortByEvent(val) {
                if (val.order === 'ascending') {
                    this.brandListTmp = _.sortBy(this.brandListTmp, (item) => {
                      return item[`${val.prop}`];
                    });
                } else if (val.order === 'descending') {
                    this.brandListTmp = _.sortBy(this.brandListTmp, (item) => {
                      return item[`${val.prop}`] * -1;
                    });
                } else{
                    this.brandListTmp = this.brandListData;
                }
                this.brandListTmp = _.map(this.brandListTmp, (item,index) => {
                    item.sort = index+1;
                    return item;
                });
                this.pageAction();
            },
            // 获取品牌列表
            getBrandList() {
                let params = {
                    type: 3,
                    cycle: this.period
                    /*page: this.listPages.currentPageNum,
                    limit: this.listPages.eachPageNum,*/
                }
                this.$http.axiosGetBy(this.$api.storeSelectionDataAnalysisGoods, params, (res) => {
                    if (res.code === 200) {
                        this.brandListTmp = res.data.list;
                        this.brandListData = res.data.list;
                        this.listPages.total = res.data.total
                        this.pageAction();
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            pageAction() {
                let start = (this.listPages.currentPageNum - 1) * this.listPages.eachPageNum;
                let end = this.listPages.eachPageNum * this.listPages.currentPageNum;
                this.brandList = this.brandListTmp.slice(start, end);
            },
            // 切换分页
            pagesCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.pageAction()
            },
            // 详情
            toDetail(row) {
                this.$router.push({
                    path: '/student/operationpromotion/commoditylist/brandDetail',
                    query: {
                        id: row.id,
                        period: this.period,
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    /*.goods-wrapper {*/
    /*    .goods-box {*/
    /*        display: flex;*/
    /*        align-items: center;*/
    /*        .goods-cover {*/
    /*            width: 48px;*/
    /*            height: 48px;*/
    /*            display: flex;*/
    /*            align-items: center;*/
    /*            justify-content: center;*/
    /*            margin-right: 10px;*/
    /*            border: 1px solid #eee;*/
    /*            box-sizing: border-box;*/
    /*            img {*/
    /*                max-width: 100%;*/
    /*                max-height: 100%;*/
    /*            }*/
    /*        }*/
    /*        .goods-right {*/
    /*            width: 1%;*/
    /*            flex: 1;*/
    /*            .goods-title {*/
    /*                color: #333;*/
    /*                line-height: 18px;*/
    /*                transition: all .3s;*/
    /*                &:hover {*/
    /*                    color: #FD4446;*/
    /*                }*/
    /*            }*/

    /*        }*/
    /*    }*/
    /*}*/
    ::v-deep .el-table {
        min-height: calc(100vh - 328px);
        .el-table__body-wrapper {
            .el-table__row {
                .ranking {
                    width: 24px;
                    height: 24px;
                    line-height: 24px;
                    text-align: center;
                    font-weight: 500;
                    background-size: cover;
                    display: inline-block;
                }
                &:first-child {
                    .ranking {
                        background: url("../../../assets/images/dataCenter/one.png") no-repeat center;
                    }
                }
                &:nth-of-type(2) {
                    .ranking {
                        background: url("../../../assets/images/dataCenter/two.png") no-repeat center;
                    }
                }
                &:nth-of-type(3) {
                    .ranking {
                        background: url("../../../assets/images/dataCenter/three.png") no-repeat center;
                    }
                }
            }
        }
    }
</style>